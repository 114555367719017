import type { QuasarIconSet } from "quasar";
import { createPinia } from "pinia";
import { Notify, Quasar } from "quasar";
import iconSet from "quasar/icon-set/material-icons";
import { createApp } from "vue";
import App from "./App.vue";
import apolloPlugin from "./plugins/apollo";
import i18nPlugin from "./plugins/i18n";
import sentryPlugin from "./plugins/sentry";
import router from "./router";
import "quasar/src/css/index.sass";
import "@/css/app.scss";
import "@quasar/extras/material-icons/material-icons.css";
import "@quasar/extras/animate/slideInLeft.css";
import "@quasar/extras/animate/slideOutLeft.css";
import "@quasar/extras/animate/slideInRight.css";
import "@quasar/extras/animate/slideOutRight.css";
import "@quasar/extras/animate/fadeIn.css";
import "@quasar/extras/animate/fadeOut.css";

const app = createApp(App);

app.use(Quasar, {
	iconSet: iconSet as QuasarIconSet,
	plugins: {
		Notify,
	},
});

app.use(sentryPlugin);
app.use(router);
app.use(apolloPlugin);
app.use(createPinia());
app.use(i18nPlugin);
app.mount("#app");
