import type { Plugin } from "vue";
import { ApolloClient, InMemoryCache } from "@apollo/client/core";
import { DefaultApolloClient, provideApolloClient } from "@vue/apollo-composable";
import { createLink } from "../util/updateHttpLink";

const tld = window.location.origin.split(".").pop();

const apolloPlugin: Plugin = {
	install: (app) => {
	// HTTP connection to the API
		const // Cache implementation
			cache = new InMemoryCache({ resultCaching: false, resultCacheMaxSize: 0 });
		// Create the apollo client
		const apolloClient = new ApolloClient({
			cache,
			link: createLink(import.meta.env.VITE_APIV3_URL.replace("TLD", tld?.includes("localhost") ? "dev" : tld ?? "dev")),
			defaultOptions: {
				watchQuery: {
					fetchPolicy: "no-cache",
				},
				query: {
					fetchPolicy: "no-cache",
				},
				mutate: {
					fetchPolicy: "no-cache",
				},
			},
		});

		app.provide(DefaultApolloClient, apolloClient);
		provideApolloClient(apolloClient);
	},
};

export default apolloPlugin;
