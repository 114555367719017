import { createHttpLink } from "@apollo/client/core";
import { useApolloClient } from "@vue/apollo-composable";

export default function updateHttpLink(uri: string) {
	const { client } = useApolloClient();

	client.setLink(createLink(uri));
}

export function createLink(uri: string) {
	return createHttpLink({
		uri,
		headers: {
			appid: "4c2cab09-d56f-4145-a63a-aa88ecee4e65",
		},
	});
}
