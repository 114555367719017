import type { Plugin } from "vue";
import de from "@autoflexbv/i18n/lib/strings/de/login.json";
import en from "@autoflexbv/i18n/lib/strings/en/login.json";
import nl from "@autoflexbv/i18n/lib/strings/nl/login.json";
import { useLocalStorage, usePreferredLanguages } from "@vueuse/core";
import { createI18n } from "vue-i18n";

export type MessageSchema = typeof nl;

// See https://vue-i18n.intlify.dev/guide/advanced/typescript.html#global-resource-schema-type-definition
declare module "vue-i18n" {
	// define the locale messages schema
	export interface DefineLocaleMessage extends MessageSchema {}

	// define the datetime format schema
	export interface DefineDateTimeFormat {}

	// define the number format schema
	export interface DefineNumberFormat {}
}

const i18nPlugin: Plugin = {
	install: (app) => {
		const preferredLanguages = usePreferredLanguages();
		const languages = {
			de,
			en,
			nl,
		};
		const language = useLocalStorage(
			"language",
			Object.keys(languages)
				.filter(lang => preferredLanguages.value.includes(lang))
				.map(lang => ({
					lang,
					priority: preferredLanguages.value.indexOf(lang),
				}))
				.sort((a, b) => a.priority - b.priority)[0]
				?.lang ?? "nl",
		);
		const i18n = createI18n({
			locale: language.value,
			fallbackLocale: "nl",
			legacy: false,
			messages: {
				// TODO Lazy load https://vue-i18n.intlify.dev/guide/advanced/lazy.html
				// @ts-expect-error en-US
				en,
				nl,
				de,
			},
		});

		// Set i18n instance on app
		app.use(i18n);
	},
};

export default i18nPlugin;
