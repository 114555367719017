import type { Plugin } from "vue";
import { name, version } from "@/../package.json";

import * as Sentry from "@sentry/vue";

const sentryPlugin: Plugin = {
	install(app) {
		const environment = location.hostname.split(".").pop() ?? "dev";

		let tracesSampleRate = 0.1;
		if (environment === "vip")
			tracesSampleRate = 0.5;
		else if (["dev", "work"].includes(environment))
			tracesSampleRate = 1;

		Sentry.init({
			app,
			dsn: "https://be85fe9fb3b57055870442e74a580a82@sentry.autoflex10.com/3",
			environment,
			integrations: [
				Sentry.replayIntegration({
					maskAllInputs: false,
					maskAllText: false,
				}),
				Sentry.browserTracingIntegration(),
			],
			logErrors: true,
			release: `${name}@${version}`,
			//* Disabled as we don't have a need for it right now
			// replaysSessionSampleRate: 0.1,
			replaysOnErrorSampleRate: tracesSampleRate,
			tracePropagationTargets: [import.meta.env.VITE_APIV3_URL.replace("TLD", environment)],
			tracesSampleRate,
			trackComponents: true,
		});
	},
};

export default sentryPlugin;
